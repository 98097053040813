<template>
  <div>

    <div  class="f-20 mb-3 d-flex justify-content-between">
      <b>PDPA EMAIL</b>
      <b-button class="btn-preview" @click="openEmailPreview()">
        <font-awesome-icon icon="mobile-alt" class="d-md-none" />
        <span class="d-none d-md-block">
          <font-awesome-icon icon="mobile-alt" class="pointer" />
          <span class="btn-text">Preview</span>
        </span>
      </b-button>
    </div>
    <div class="title-tabs">PDPA Title</div>

    <b-container class="no-gutters bg-white">
      <div v-if="isLoading">
      <OtherLoading />
    </div>
      <b-tabs
      v-else
        pills
        card
        :vertical="isMobile"
        nav-wrapper-class="tabs-title-spacing"
        v-model="tabIndex"
        @activate-tab="handleTabsChange"
      >
        <b-tab v-for="tab of tabs" :title="tab.topic" :key="tab.id" lazy >
          <b-card-text>
            <b-skeleton-wrapper :loading="isLoading">
              <EmailEditor
                ref="emailEditor"
                pdpaEmail
                :variableItem="
                  MCEVariable.map((el) => {
                    return { label: el, value: '{{' + el + '}}' };
                  })
                "
                @onDataChange="(val) => setValue(val)"
                v-model="MCEvalue"
                :template="emailTemplate"
                @clearField="clearField"
              />
            </b-skeleton-wrapper>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-container>
    <FooterAction routePath="/setting" @submit="exportHtml()" />
    <ModalLoading ref="modalLoading" />
  </div>
</template>
<script>
import ModalLoading from "@/components/modal/ModalLoading";
import EmailEditor from "@/components/inputs/EmailEditor";
import OtherLoading from "@/components/loading/OtherLoading";

export default {
  components: { ModalLoading, EmailEditor, OtherLoading },
  data() {
    return {
      tabIndex: 1,
      tabs: [],
      MCEvalue: "",
      design: "",
      freezeMCEValue: "",
      MCEVariable: [],
      id: 1,
      screenSize: window.innerWidth,
      newTabIndex: 1,
      prevTabIndex: 1,
      isLoading: false,
      emailTemplate: { html: "", css: "" },
      newId: 0,
    };
  },
  mounted() {},
  watch: {
    screenSize(val) {
      return val;
    },
  },
  computed: {
    isMobile() {
      return this.screenSize < 1024 ? false : true;
    },
  },
  created() {
    this.getList();
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    openEmailPreview() {
      document.documentElement.style.overflow = 'hidden';
      this.$refs.emailEditor[0].openPreviewPopup();
    },
    setValue(val) {
      this.MCEvalue = val.compileHtml;
      this.emailTemplate = val.template;
    },
    async exportHtml() {
      const { template } = await this.$refs.emailEditor[0].compileEmail();

      var data = {
        value: template.html,
        design: template.css,
      };
      await this.saveForm(data);
    },
    onResize(e) {
      this.screenSize = window.innerWidth;
    },
    async getList() {
      this.isLoading = true;
      const response = await this.axios(`/setting/pdpaemail`);

      this.tabs = response.data.detail.filter((el) => el.topic);
      this.id = response.data.detail[0].id;
      this.getPDPAbyEmail(this.id, true);
      this.isLoading = false;
    },
    async getPDPAbyEmail(id, condition) {
      this.isLoading = true;
      this.id = id;
      if (this.freezeMCEValue !== this.MCEvalue || condition) {
        const response = await this.axios(`/setting/pdpaemail/${id}`);

        if (!response.data.detail.email_info) {
          response.data.detail.email_info = {
            value: "",
            design: "",
          };
        }

        this.emailTemplate = {
          html: response.data.detail.email_info.value
            ? response.data.detail.email_info.value
            : "",
          css: response.data.detail.email_info.design
            ? response.data.detail.email_info.design
            : "",
        };

        this.freezeMCEValue = response.data.detail.email_info.value;

        this.MCEVariable = response.data.detail.variable;
        this.isLoading = false;
      }
    },
    addVariable(val) {
      var ele = document.getElementsByClassName("mce-edit-focus");
      ele[0].getElementsByTagName("p")[0].innerText += `{{${val}}}`;
      // this.MCEvalue += `{{${val}}}`;
    },
    async saveForm(val) {
      var payload = {
        id: this.tabs[this.tabIndex].id,
        ...val,
      };
      this.$refs.modalLoading.show();
      return this.axios.post(`/setting/pdpaemail`, payload).then((res) => {
        if (res.data.result) {
          this.successAlert();

          this.freezeMCEValue = val.value;
        } else {
          this.errorAlert(res.data.message);
        }
        this.$refs.modalLoading.hide();
        return res;
      });
    },
    async handleTabsChange(newTabIndex, prevTabIndex, bvEvent) {
      if (this.tabIndex != newTabIndex) {
        bvEvent.preventDefault();
        const { template } = await this.$refs.emailEditor[0].compileEmail();

        if (this.freezeMCEValue !== template.html) {
          this.prevTabIndex = prevTabIndex;
          this.newTabIndex = newTabIndex;
          this.$swal2
            .fire({
              text: "เนื่องจากข้อมูลมีการแก้ไขคุณหรือต้องบันทึกข้อมูลหรือไม่ ?",
              title: "ต้องการบันทึกข้อมูลหรือไม่ ?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "บันทึก",
              denyButtonText: `ไม่บันทึก`,
              cancelButtonText: `ยกเลิก`,
              showDenyButton: true,
              reverseButtons: true,
              customClass: {
                actions: "my-actions",
                cancelButton: "order-1 right-gap",
                confirmButton: "order-3",
                denyButton: "order-2",
              },
            })
            .then(async (result) => {
              this.newId = this.tabs[newTabIndex].id;
              if (result.isConfirmed) {
                var data = {
                  value: template.html,
                  design: template.css,
                };
                const res = await this.saveForm(data);
                if (res.data.result) {
                  this.tabIndex = newTabIndex;
                  this.getPDPAbyEmail(this.newId, true);
                }
              } else if (result.isDenied) {
                this.tabIndex = newTabIndex;
                this.getPDPAbyEmail(this.newId, true);
              }
            });
        } else {
          var newId = this.tabs[newTabIndex].id;
          this.tabIndex = newTabIndex;
          this.getPDPAbyEmail(newId, true);
        }
      }
    },
    clearField() {},
  },
};
</script>
<style class="scoped">
.btn-preview button,
.btn-preview {
  color: var(--primary-color) !important;
  border-color: var(--primary-color);
  font-weight: 100;
  background-color: white;
}
.f-20 {
  font-size: 20px;
}
.nav-link {
  text-decoration: unset;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--font-color) !important;
  background-color: var(--theme-secondary-color);
}
.tabs-title-spacing {
  /* padding: 0px 15px 0 15px !important; */
  margin: 10px 0px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.btn-fixed {
  min-width: 150px;
}
.my-actions {
  margin: 0 2em;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}

.right-gap {
  margin-right: auto;
}
iframe {
  height: 80vh !important;
  max-height: 100% !important;
  min-width: unset !important;
}
@media screen and (max-width: 1024px) {
  .tabs-title-spacing {
    border-right: unset;
  }
}
</style>
